<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum"></HeaderTop>
        <!-- <div class="header" :style="{background:'url('+banner+')',backgroundSize:cover}">
      <HeaderTop :parentData="dataNum"></HeaderTop>
      <div class="headerContText">
        <div class="headerContTextTit">
          新闻中心
        </div>
        <div class="headerContTextTip">
          与未来同行，让我们的生活更美好
        </div>
      </div>
    </div> -->
        <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
        </el-carousel>
        <div
            class="marketBox"
            style="background: #fafafa"
            v-if="saleList.length > 0"
        >
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">今日特卖</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/temaiList', 5)"
                >
                    更多产品
                </div>
            </div>
            <div class="jrtmBox">
                <div
                    class="jrtmLi"
                    v-for="(item, index) in saleList"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" />
                    <div class="jrtmName">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <div class="tabNav">
            <!-- <div v-for="(item,index) in newsListOne" :key="index"> -->
            <div
                class="tabNavLi"
                v-for="(item, index) in newsListOne"
                :key="index"
            >
                <div class="tabNavTime">{{ item.pubDate }}</div>
                <!-- <div class="tabNavTime">{{ item.createDate }}</div> -->
                <div class="tabNavName">{{ item.title }}</div>
                <div class="tabNavImg">
                    <img :src="item.pic" alt="" />
                </div>
                <!-- <div class="tabNavSort">
          {{item.title}}
        </div> -->
                <div class="tabNavBtn" @click="toUrl('/newXq', item.id)">
                    了解更多信息<span class="el-icon-arrow-right"></span>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="listBox">
            <div class="listLi" v-for="(item, index) in newsList" :key="index">
                <div class="listLeft">
                    <div class="listImg">
                        <img :src="item.pic" alt="" />
                    </div>
                    <div class="listCont">
                        <div class="listName">
                            {{ item.title }}
                        </div>
                        <div class="listTip">
                            {{ item.pubDate }}
                            <!-- {{ item.createDate }} -->
                        </div>
                    </div>
                </div>
                <div class="listRight" @click="toUrl('/newXq', item.id)">
                    了解更多信息<span class="el-icon-arrow-right"></span>
                </div>
            </div>
        </div>
        <!-- <div class="listBox">
      <div class="listLi" v-for="(item,index) in newsList" :key="index">
        <div class="listLeft">
          <div class="listTime">2024.07.31</div>
          <div class="listCont">
            <div class="listName">
              以良法促粮食产业发展 以善治保粮食生产者增收
            </div>
            <div class="listTip">
              粮食安全保障法自2024年6月1日起施行。这部法律深入实施国家粮食安全战略，建立完善粮食安全保障制度体系，全方位夯实粮食安全根基，为确保国家粮食安全提供了有力法治保障。这是关系“中国饭碗”的头等大事...
            </div>
          </div>
        </div>
        <div class="listRight" @click="toUrl('/newXq')">
          了解更多信息<span class="el-icon-arrow-right"></span>
        </div>
      </div>
    </div> -->
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
        <!-- <FooterBottom></FooterBottom> -->
    </div>
</template>

<script>
import { index } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 5,
            // banner:JSON.parse(localStorage.getItem('webMsg')).picture.lx,
            newsList: [],
            newsListOne: [],

            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            lunboList: [],

            saleList: []
        }
    },
    created() {
        this.listQuery.page = 1
        this.companyOneList = []
        this.getInit()
    },
    methods: {
        swiperIndex(index) {
            console.log(index)
            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },

        toUrl(urlSrc, id) {
            if (urlSrc == '/temaiList') {
                if (localStorage.getItem('token')) {
                    this.$router.push({ path: urlSrc, query: { type: id } })
                } else {
                    this.$message({
                        message: '请登录后操作',
                        type: 'warning'
                    })
                }
                return
            }
            this.$router.push({ path: urlSrc, query: { id: id } })
        },
        pageSizeChangeHandle(val) {
            console.log(val, 'pageSizeChangeHandle')
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            console.log(val, 'pageCurrentChangeHandle')

            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            index({
                type: 5,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.newsList.total)
                    let newA = []
                    let newB = []
                    for (var a = 0; a < res.data.newsList.records.length; a++) {
                        if (a < 3) {
                            newA.push(res.data.newsList.records[a])
                        }
                        if (a >= 3) {
                            newB.push(res.data.newsList.records[a])
                        }
                    }

                    if (res.data.saleList.records.length > 0) {
                        for (
                            var c = 0;
                            c < res.data.saleList.records.length;
                            c++
                        ) {
                            res.data.saleList.records[c].pic =
                                res.data.saleList.records[c].pic.split(',')
                        }
                    }
                    this.saleList = res.data.saleList.records
                    this.newsListOne = newA
                    this.newsList = newB
                    this.lunboList = res.data.carouselList.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;
        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;
            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;
                object-fit: cover;
            }
            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
            }
        }
        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }
        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 8.02rem;
    background: url('@/assets/xwBg.png') no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding-left: 3.72rem;
    box-sizing: border-box;

    .headerContText {
        .headerContTextTit {
            color: #ffffff;
            font-size: 0.72rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .headerContTextTip {
            color: #ffffff;
            font-size: 0.2rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
}
.tabNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3rem;
    margin-bottom: 0.6rem;
    // .tablli{
    //   margin-right: 0.38rem;
    // }
    // .tablli:nth-child(3){
    //   margin-right: 0;
    // }
    .tabNavLi {
        width: 4.92rem;
        // height: 7.54rem;
        height: 6rem;

        opacity: 1;
        background: #ffffff;
        box-shadow: 0.02rem 0.02rem 0.04rem 0 #00000000;
        padding: 0.43rem 0.3rem;
        box-sizing: border-box;
        margin-right: 0.38rem;
        .tabNavTime {
            color: #545454;
            font-size: 0.16rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
        .tabNavName {
            color: #000000;
            font-size: 0.24rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
            height: 0.68rem;
            display: -webkit-box; /* 显示多行文本容器 */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*显示行数*/
            overflow: hidden; /*隐藏多出部分文字*/
            text-overflow: ellipsis; /*用省略号代替多出部分文字*/
        }
        .tabNavImg {
            width: 4.35rem;
            height: 3.19rem;
            margin-top: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tabNavSort {
            color: #545454;
            font-size: 0.16rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            margin-top: 0.25rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 定义文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .tabNavBtn {
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            margin-top: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            span {
                font-size: 0.13rem;
            }
        }
    }
    .tabNavLi:nth-child(3) {
        margin-right: 0;
    }
    .tabNavLi:hover {
        background: #00b34a;
        box-shadow: 0.02rem 0.02rem 0.04rem 0 #00000000;
        .tabNavTime {
            color: #ffffff;
        }
        .tabNavName {
            color: #ffffff;
        }
        .tabNavSort {
            color: #ffffff;
        }
        .tabNavBtn {
            background: #ffffff;
            color: #00943f;
        }
    }
}
.listBox {
    .listLi {
        width: 15.53rem;
        height: 2.68rem;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0 0.06rem 0.06rem 0 #0000000f;
        margin: 0 auto 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.47rem;
        box-sizing: border-box;
        .listLeft {
            display: flex;
            align-items: center;
            .listTime {
                color: #00943f;
                font-size: 0.18rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }
            .listImg {
                width: 3rem;
                height: 2rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .listCont {
                margin-left: 0.44rem;
                width: 8.24rem;
                .listName {
                    color: #000000;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .listTip {
                    color: #54545480;
                    font-size: 0.16rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.28rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 定义文本的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .listRight {
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            margin-top: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            span {
                font-size: 0.13rem;
            }
        }
    }
}
</style>