<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="box">
            <div class="boxTit">订单详情</div>
            <div class="boxMin" v-if="type == userInfo.userType">
                <div class="boxTop">基本信息</div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">订单编号</div>
                        <div class="boxNum">
                            {{ dataList.orderCode || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">价格</div>
                        <div class="boxNum" v-if="dataList.priceType == 0">
                            {{ dataList.price || '' }}/斤
                        </div>
                        <div class="boxNum" v-if="dataList.priceType == 1">
                            以实时价为准
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">采购数量</div>
                        <div class="boxNum">{{ dataList.num || '' }}吨</div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">交易品种</div>
                        <div class="boxNum">
                            {{ dataList.productName || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">交易类型</div>
                        <div class="boxNum">
                            {{ dataList.dealType || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">产地</div>
                        <div class="boxNum">
                            {{ dataList.producer || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">发布时间</div>
                        <div class="boxNum">
                            {{ dataList.releaseTime || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">履约保证方式</div>
                        <div class="boxNum">
                            {{ dataList.ensureMethod || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">信息有效期</div>
                        <div class="boxNum">
                            {{ dataList.messageValidity || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">包装方式</div>
                        <div class="boxNum">
                            {{ dataList.packing || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">抵扣标准</div>
                        <div class="boxNum">
                            {{ dataList.deductions || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">备注</div>
                        <div class="boxNum">
                            {{ dataList.remark || '' }}
                        </div>
                    </div>
                    <div class="boxLi" @click.stop="htDown(dataList.orderCode)">
                        <div class="boxNum" style="color: #339cff">
                            订单合同
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type == userInfo.userType">
                <div
                    class="gysBox"
                    v-for="(item, index) in subOrderList"
                    :key="index"
                >
                    <div class="gysBoxTit">
                        {{
                            (item.type == 1 && '粮市(成品制造商)') ||
                            (item.type == 2 && '粮坊(采购商)') ||
                            (item.type == 3 && '粮仓(供应商)')
                        }}({{ item.companyB }})
                    </div>
                    <div class="gysNum">配送量：{{ item.num }}吨</div>
                    <div class="ddfp" v-if="item.driverOrderList.length <= 0">
                        粮运(司机)待分配
                    </div>
                    <div
                        class="gysLi"
                        v-for="(obj, objIndex) in item.driverOrderList"
                        :key="objIndex"
                    >
                        <div class="gysTop">
                            <div class="gysName">{{ obj.driverName }}</div>
                            <div
                                class="gysStatus"
                                v-if="obj.status >= 0 && obj.status <= 3"
                            >
                                待运输
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status >= 4 && obj.status <= 7"
                                style="background: #2dc9a11a; color: #2dc9a1"
                            >
                                运输中
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status == 8"
                                style="background: #ff8d1a1a; color: #ff8d1a"
                            >
                                已完成
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status == 10"
                                style="background: #ff57331a; color: #ff5733"
                            >
                                售后
                            </div>
                        </div>
                        <div class="gysDisplay">
                            <div class="gysDisplayBottom">
                                <div
                                    class="gysDisplayLi"
                                    v-if="obj.loadNetWeight"
                                >
                                    运输：<span>{{ obj.loadNetWeight }}kg</span>
                                </div>
                                <div class="gysDisplayLi">
                                    联系方式：<span>{{ obj.telphone }}</span>
                                </div>
                            </div>
                            <div class="gysBtn" v-if="tabIndex == 1">
                                <div
                                    class="gysBtnLi"
                                    v-if="obj.status >= 2 && obj.status <= 7"
                                    @click="
                                        toUrl(
                                            '/ysSh',
                                            obj.id,
                                            dataList.productName
                                        )
                                    "
                                >
                                    核验收货
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="toUrl('/driveXq', obj.id)"
                                >
                                    查看订单
                                </div>
                            </div>
                            <div
                                class="gysBtn"
                                v-if="
                                    obj.status >= 0 &&
                                    obj.status <= 3 &&
                                    tabIndex == 2
                                "
                            >
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            1
                                        )
                                    "
                                >
                                    核验
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            2
                                        )
                                    "
                                >
                                    装车
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            3
                                        )
                                    "
                                >
                                    过磅
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="truckFinish(obj.id)"
                                >
                                    装车完成
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxMin" v-if="type != userInfo.userType">
                <div class="boxTop">基本信息</div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">订单编号</div>
                        <div class="boxNum">
                            {{ dataMsg.orderCode || '' }}
                        </div>
                    </div>

                    <div class="boxLi">
                        <div class="boxName">价格</div>
                        <div
                            class="boxNum"
                            v-if="dataMsg.orderResult.priceType == 0"
                        >
                            {{ dataMsg.orderResult.price || '' }}/斤
                        </div>
                        <div
                            class="boxNum"
                            v-if="dataMsg.orderResult.priceType == 1"
                        >
                            以实时价为准
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">供应数量</div>
                        <div class="boxNum">{{ dataMsg.num || '' }}吨</div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">交易品种</div>
                        <div class="boxNum">
                            {{ dataList.productName || '' }}
                        </div>
                    </div>
                    <!-- <div class="boxLi">
                        <div class="boxName">
                            交易类型
                        </div>
                        <div class="boxNum">
                            {{ dataList.dealType || '' }}
                        </div>
                    </div> -->
                    <!-- <div class="boxLi">
                        <div class="boxName">
                            产地
                        </div>
                        <div class="boxNum">
                            {{ dataList.producer || '' }}
                        </div>
                    </div> -->
                    <div class="boxLi">
                        <div class="boxName">指派时间</div>
                        <div class="boxNum">
                            {{ dataMsg.createDate || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">采购公司</div>
                        <div class="boxNum">
                            {{ dataList.companyA || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">采购人</div>
                        <div class="boxNum">
                            {{ dataList.promoter || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">采购人电话</div>
                        <div class="boxNum">
                            {{ dataList.buyerPhone || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">交货地</div>
                        <div class="boxNum">
                            {{ dataList.deliveryPlace || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">抵扣标准</div>
                        <div class="boxNum">
                            {{ dataList.deductions || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">备注</div>
                        <div class="boxNum">
                            {{ dataList.remark || '' }}
                        </div>
                    </div>

                    <div class="boxLi" @click.stop="htDown(dataList.orderCode)">
                        <div class="boxNum" style="color: #339cff">
                            订单合同
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type != userInfo.userType">
                <div class="gysBox">
                    <div class="gysBoxTit">
                        {{
                            (dataMsg.orderResult.type == 1 &&
                                '粮市(成品制造商)') ||
                            (dataMsg.orderResult.type == 2 && '粮坊(采购商)') ||
                            (dataMsg.orderResult.type == 3 && '粮仓(供应商)')
                        }}({{ dataMsg.orderResult.companyA }})
                    </div>
                    <div class="gysNum">配送量：{{ dataMsg.num }}吨</div>
                    <div class="ddfp" v-if="subOrderList.length <= 0">
                        粮运(司机)待分配
                    </div>
                    <div
                        class="gysLi"
                        v-for="(obj, objIndex) in subOrderList"
                        :key="objIndex"
                        @click="toUrl('/driveXq', obj.id)"
                    >
                        <div class="gysTop">
                            <div class="gysName">{{ obj.driverName }}</div>
                            <div
                                class="gysStatus"
                                v-if="obj.status >= 0 && obj.status <= 3"
                            >
                                待运输
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status >= 4 && obj.status <= 7"
                                style="background: #2dc9a11a; color: #2dc9a1"
                            >
                                运输中
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status == 8"
                                style="background: #ff8d1a1a; color: #ff8d1a"
                            >
                                已完成
                            </div>
                            <div
                                class="gysStatus"
                                v-if="obj.status == 10"
                                style="background: #ff57331a; color: #ff5733"
                            >
                                售后
                            </div>
                        </div>
                        <div class="gysDisplay">
                            <div class="gysDisplayBottom">
                                <div
                                    class="gysDisplayLi"
                                    v-if="obj.loadNetWeight"
                                >
                                    运输：<span>{{ obj.loadNetWeight }}kg</span>
                                </div>
                                <div class="gysDisplayLi">
                                    联系方式：<span>{{ obj.telphone }}</span>
                                </div>
                            </div>
                            <div class="gysBtn" v-if="tabIndex == 1">
                                <div
                                    class="gysBtnLi"
                                    v-if="obj.status >= 2 && obj.status <= 7"
                                    @click="
                                        toUrl(
                                            '/ysSh',
                                            obj.id,
                                            dataList.productName
                                        )
                                    "
                                >
                                    核验收货
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="toUrl('/driveXq', obj.id)"
                                >
                                    查看订单
                                </div>
                            </div>
                            <div
                                class="gysBtn"
                                v-if="
                                    obj.status >= 0 &&
                                    obj.status <= 3 &&
                                    tabIndex == 2
                                "
                            >
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            1
                                        )
                                    "
                                >
                                    核验
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            2
                                        )
                                    "
                                >
                                    装车
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="
                                        toUrl(
                                            '/hyzcgbInpt',
                                            obj.id,
                                            dataList.productName,
                                            3
                                        )
                                    "
                                >
                                    过磅
                                </div>
                                <div
                                    class="gysBtnLi"
                                    @click="truckFinish(obj.id)"
                                >
                                    装车完成
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { orderInfo, orderContract, delivery } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            dataList: {},
            subOrderList: [],
            dataMsg: {},
            id: '',
            type: '',
            tabIndex: ''
        }
    },
    created() {
        this.tabIndex = this.$route.query.tabIndex
        this.type = this.$route.query.type
        this.id = this.$route.query.id
        this.init()
    },
    methods: {
        truckFinish(id) {
            delivery({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '发货成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        htDown(id) {
            orderContract({ orderCode: id }).then((res) => {
                console.log(res)
                if (res.data.contract) {
                    window.location.href = res.data.contract
                } else {
                    this.$message({
                        message: '暂无合同',
                        type: 'warning'
                    })
                }
            })
        },
        toUrl(urlSrc, id, name, type) {
            if (urlSrc == '/hyzcgbInpt') {
                this.$router.push({
                    path: urlSrc,
                    query: { id: id, name: name, type: type }
                })
                return
            }
            if (urlSrc == '/ysSh') {
                this.$router.push({
                    path: urlSrc,
                    query: { id: id, name: name }
                })
            } else {
                this.$router.push({ path: urlSrc, query: { id: id } })
            }
        },
        init() {
            orderInfo({ orderId: this.id, type: this.type }).then((res) => {
                if (res.code == 0) {
                    // if (this.userInfo.userType == 3) {
                    //     this.dataList = res.data.orderResult
                    //     this.subOrderList = res.data.driverOrderList
                    //     this.dataMsg = res.data
                    // } else {
                    //     this.dataList = res.data
                    //     this.subOrderList = res.data.subOrderList
                    // }
                    if (this.type != this.userInfo.userType) {
                        this.dataList = res.data.orderResult
                        this.subOrderList = res.data.driverOrderList
                        this.dataMsg = res.data
                    } else {
                        this.dataList = res.data
                        this.subOrderList = res.data.subOrderList
                    }
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 15.68rem;
    border-radius: 0.32rem;
    opacity: 1;
    background: #ffffff;
    margin: 0 auto;
    padding: 0.43rem 0.59rem;
    box-sizing: border-box;

    .boxTit {
        color: #000000;
        font-size: 0.5rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
    }

    .boxMin {
        width: 14.48rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.4rem;

        .boxTop {
            width: 10.6rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(
                90deg,
                rgba(47, 165, 99, 0.5) 0%,
                #2fa56300 100%
            );
            color: #2fa563;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            padding-left: 0.65rem;
        }

        .boxCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.5rem 0.65rem;
            box-sizing: border-box;

            .boxLi {
                width: 50%;
                margin-bottom: 0.5rem;

                .boxName {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                }

                .boxNum {
                    color: #343231;
                    font-size: 0.28rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
        }
    }

    .gysBox {
        width: 100%;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.36rem;
        padding-bottom: 0.3rem;
        .gysBoxTit {
            width: 8.06rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(
                90deg,
                rgba(0, 128, 255, 0.3) 0%,
                #0080ff00 100%
            );
            color: #339cff;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
        }

        .gysNum {
            color: #343231;
            font-size: 0.3rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            padding: 0.3rem 0.5rem 0;
            box-sizing: border-box;
        }

        .ddfp {
            padding: 0.3rem 0.5rem 0;
            box-sizing: border-box;
            color: #343231;
            font-size: 0.3rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }

        .gysLi {
            padding: 0.3rem 0.5rem 0.5rem;
            box-sizing: border-box;
            border-bottom: 0.01rem solid #f0f4f6;

            .gysTop {
                display: flex;
                align-items: center;

                .gysName {
                    color: #343231;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                }

                .gysStatus {
                    color: #339cff;
                    width: 1.14rem;
                    height: 0.38rem;
                    border-radius: 0.19rem;
                    background: #0080ff1a;
                    font-size: 0.22rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.2rem;
                    text-align: center;
                    line-height: 0.38rem;
                }
            }

            .gysDisplay {
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .gysDisplayBottom {
                    display: flex;
                    align-items: center;

                    .gysDisplayLi {
                        color: #868588;
                        margin-right: 1rem;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';

                        span {
                            color: #555555;
                            font-size: 0.28rem;
                            font-weight: 500;
                            font-family: 'SourceHanSansCN';
                        }
                    }
                }

                .gysBtn {
                    display: flex;
                    align-items: center;

                    .gysBtnLi {
                        margin-left: 0.5rem;
                        color: #339cff;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                    }
                }
            }
        }
    }
}
</style>