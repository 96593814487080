import request from "@/utils/request"
// 账号密码登录
export function login(data) {
    return request({
        method: 'post',
        url: 'login',
        data: data
    })
}
// 图形验证码
export function captcha(uuid) {
    return request({
        method: 'get',
        url: 'captcha',
        params: {
            uuid: uuid
        },
        responseType: 'arraybuffer', // 将响响应的数据类型修改成 arraybuffer
    })
}
// 发送验证码
export function sendSMS(data) {
    return request({
        method: 'get',
        url: 'sendSMS',
        params: data,
    })
}

// 验证码登录
export function codeLogin(data) {
    return request({
        method: 'post',
        url: 'codeLogin',
        data: data,
    })
}
// 注册
export function register(data) {
    return request({
        method: 'post',
        url: 'register',
        data: data,
    })
}

// 用户信息
export function userInfo(data) {
    return request({
        method: 'get',
        url: 'userInfo',
        params: data,
    })
}
// 网站信息
export function getWeb(data) {
    return request({
        method: 'get',
        url: 'getWeb',
        params: data,
    })
}

// 首页信息
export function index(data) {
    return request({
        method: 'get',
        url: 'index',
        params: data,
    })
}

// 种子、化肥、农药
export function categoryList(data) {
    return request({
        method: 'get',
        url: 'categoryList',
        params: data,
    })
}

// 商品详情
export function getProductById(data) {
    return request({
        method: 'get',
        url: 'getProductById',
        params: data,
    })
}


export function getGoods(data) {
    return request({
        method: 'get',
        url: 'getGoods',
        params: data,
    })
}

// 公司详情
export function getCompanyById(data) {
    return request({
        method: 'get',
        url: 'getCompanyById',
        params: data,
    })
}
// 新闻详情
export function getNewsById(data) {
    return request({
        method: 'get',
        url: 'getNewsById',
        params: data,
    })
}
// 评价列表
export function getGoodsComment(data) {
    return request({
        method: 'get',
        url: 'getGoodsComment',
        params: data,
    })
}
// 种子、农药列表
export function goodsList(data) {
    return request({
        method: 'get',
        url: 'goodsList',
        params: data,
    })
}
// 种子、农药详情
export function goodsInfo(data) {
    return request({
        method: 'get',
        url: 'goodsInfo',
        params: data,
    })
}

// 种子、农药申请
export function askFor(data) {
    return request({
        method: 'post',
        url: 'askFor',
        data: data,
    })
}

// 商品列表
export function moreProduct(data) {
    return request({
        method: 'get',
        url: 'moreProduct',
        params: data,
    })
}

// 企业收藏
export function collect(data) {
    return request({
        method: 'get',
        url: 'collect',
        params: data,
    })
}

// 我的收藏
export function myCollect(data) {
    return request({
        method: 'get',
        url: 'myCollect',
        params: data,
    })
}

// 收款信息
export function setUser(data) {
    return request({
        method: 'post',
        url: 'setUser',
        data: data,
    })
}

// 上传图片
export function upload(data) {
    return request({
        method: 'post',
        url: 'upload',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
// 粮市(成品制造商)入驻
export function joinIn(data) {
    return request({
        method: 'post',
        url: 'joinIn',
        data: data,
    })
}
// 获取协议
export function getValue(data) {
    return request({
        method: 'get',
        url: 'getValue',
        params: data,
    })
}
// 获取粮票记录
export function billList(data) {
    return request({
        method: 'get',
        url: 'billList',
        params: data,
    })
}
// 获取粮票提交的信息
export function creditInfo(data) {
    return request({
        method: 'get',
        url: 'creditInfo',
        params: data,
    })
}

// 待办订单
export function toDoList(data) {
    return request({
        method: 'get',
        url: 'ext/toDoList',
        params: data,
    })
}
// 删除订单


export function delOrder(data) {
    return request({
        method: 'get',
        url: 'delOrder',
        params: data,
    })
}
// 取消订单
export function cancelOrder(data) {
    return request({
        method: 'get',
        url: 'cancelOrder',
        params: data,
    })
}

// 订单详情
export function orderInfo(data) {
    return request({
        method: 'get',
        url: 'ext/orderInfo',
        params: data,
    })
}

// 订单详情
export function orderDetails(data) {
    return request({
        method: 'get',
        url: 'orderDetails',
        params: data,
    })
}

// 发布订单
export function publishOrder(data) {
    return request({
        method: 'post',
        url: 'publishOrder',
        data: data,
    })
}

// 全部订单列表
export function orderList(data) {
    return request({
        method: 'get',
        url: 'ext/orderList',
        params: data,
    })
}

// 合同详情

export function orderContract(data) {
    return request({
        method: 'get',
        url: 'orderContract',
        params: data,
    })
}

// 核验收货
export function lastCheck(data) {
    return request({
        method: 'post',
        url: 'lastCheck',
        data: data,
    })
}

// 上传付款凭证
export function payment(data) {
    return request({
        method: 'post',
        url: 'payment',
        data: data,
    })
}

// 粮运(司机)列表
export function driverList(data) {
    return request({
        method: 'get',
        url: 'driverList',
        params: data,
    })
}

// 核检过磅装车
export function loading(data) {
    return request({
        method: 'post',
        url: 'loading',
        data: data,
    })
}
export function loadingCheck(data) {
    return request({
        method: 'post',
        url: 'loadingCheck',
        data: data,
    })
}
export function unloading(data) {
    return request({
        method: 'post',
        url: 'unloading',
        data: data,
    })
}

export function delivery(data) {
    return request({
        method: 'post',
        url: 'delivery',
        data: data,
    })
}


// 公司的商品
export function getCompanyProduct(data) {
    return request({
        method: 'get',
        url: 'getCompanyProduct',
        params: data,
    })
}
export function getCompanyOrder(data) {
    return request({
        method: 'get',
        url: 'getCompanyOrder',
        params: data,
    })
}

export function getCompanyOrderExt(data) {
    return request({
        method: 'get',
        url: 'getCompanyOrderExt',
        params: data,
    })
}

export function subscribe(data) {
    return request({
        method: 'get',
        url: 'subscribe',
        params: data,
    })
}

// 退出
export function logout(data) {
    return request({
        method: 'post',
        url: 'logout',
        data: data,
    })
}
export function carouselInfo(data) {
    return request({
        method: 'get',
        url: 'carouselInfo',
        params: data,
    })
}

// 收货地址列表

export function getUserAddressList(data) {
    return request({
        method: 'get',
        url: 'getUserAddressList',
        params: data,
    })
}
export function getRegionTree(data) {
    return request({
        method: 'get',
        url: 'getRegionTree',
        params: data,
    })
}


export function addAddress(data) {
    return request({
        method: 'post',
        url: 'addAddress',
        data: data,
    })
}



export function updateAddress(data) {
    return request({
        method: 'post',
        url: 'updateAddress',
        data: data,
    })
}
export function getDetail(data) {
    return request({
        method: 'get',
        url: 'getDetail',
        params: data,
    })
}

export function delAddress(data) {
    return request({
        method: 'get',
        url: 'delAddress',
        params: data,
    })
}

export function isDefault(data) {
    return request({
        method: 'get',
        url: 'isDefault',
        params: data,
    })
}

// 运费。


export function getConfig(data) {
    return request({
        method: 'get',
        url: 'getConfig',
        params: data,
    })
}

export function getRegionList(data) {
    return request({
        method: 'get',
        url: 'getRegionList',
        params: data,
    })
}
export function addConfig(data) {
    return request({
        method: 'post',
        url: 'addConfig',
        data: data,
    })
}
export function getConfigDetail(data) {
    return request({
        method: 'get',
        url: 'getConfigDetail',
        params: data,

    })
}
export function updateConfig(data) {
    return request({
        method: 'post',
        url: 'updateConfig',
        data: data,
    })
}
// 企业商品

export function goodsPage(data) {
    return request({
        method: 'get',
        url: 'goodsPage',
        params: data,

    })
}
export function updateStatus(data) {
    return request({
        method: 'get',
        url: 'updateStatus',
        params: data,

    })
}
export function delGoods(data) {
    return request({
        method: 'get',
        url: 'delGoods',
        params: data,

    })
}
export function addGoods(data) {
    return request({
        method: 'post',
        url: 'addGoods',
        data: data,
    })
}
export function moreSaleList(data) {
    return request({
        method: 'get',
        url: 'moreSaleList',
        params: data,

    })
}


// 发票管理
export function getUserInvoiceInfo(data) {
    return request({
        method: 'get',
        url: 'getUserInvoiceInfo',
        params: data,

    })
}
export function getInvoiceInfoDetail(data) {
    return request({
        method: 'get',
        url: 'getInvoiceInfoDetail',
        params: data,

    })
}

export function addInvoiceInfo(data) {
    return request({
        method: 'post',
        url: 'addInvoiceInfo',
        data: data,
    })
}
export function updateInvoiceInfo(data) {
    return request({
        method: 'post',
        url: 'updateInvoiceInfo',
        data: data,
    })
}

export function delInvoiceInfo(data) {
    return request({
        method: 'get',
        url: 'delInvoiceInfo',
        params: data,

    })
}
export function defaultInvoiceInfo(data) {
    return request({
        method: 'get',
        url: 'defaultInvoiceInfo',
        params: data,

    })
}
// 加入购物车
export function addShoppingCart(data) {
    return request({
        method: 'get',
        url: 'addShoppingCart',
        params: data,

    })
}

// 购物车列表
export function getShoppingCartList(data) {
    return request({
        method: 'get',
        url: 'getShoppingCartList',
        params: data,

    })
}
// 购物车计算价格
export function statisticalPrices(data) {
    return request({
        method: 'get',
        url: 'statisticalPrices',
        params: data,

    })
}
// 购物车数量加减
export function addOrDel(data) {
    return request({
        method: 'get',
        url: 'addOrDel',
        params: data,

    })
}

// 购物车商品删除
export function removeCartGoods(data) {
    return request({
        method: 'get',
        url: 'removeCartGoods',
        params: data,

    })
}

// 获取默认地址
export function getDefaultAddress(data) {
    return request({
        method: 'get',
        url: 'getDefaultAddress',
        params: data,

    })
}
// 计算带有运费的价格

export function sumCartPrice(data) {
    return request({
        method: 'get',
        url: 'sumCartPrice',
        params: data,

    })
}
// 确认订单
export function cartToOrder(data) {
    return request({
        method: 'get',
        url: 'cartToOrder',
        params: data,

    })
}
// 获取公户信息
export function lzdAccount(data) {
    return request({
        method: 'get',
        url: 'lzdAccount',
        params: data,

    })
}
// 获取订单详情
export function orderDetail(data) {
    return request({
        method: 'get',
        url: 'orderDetail',
        params: data,

    })
}

// 立即支付订单
export function uploadVoucher(data) {
    return request({
        method: 'post',
        url: 'uploadVoucher',
        data: data,
    })
}
// 取消订单
export function orderCancel(data) {
    return request({
        method: 'get',
        url: 'orderCancel',
        params: data,

    })
}

// 订单列表
export function getOrderPage(data) {
    return request({
        method: 'get',
        url: 'getOrderPage',
        params: data,

    })
}
// 确认收货
export function confirmReceipt(data) {
    return request({
        method: 'get',
        url: 'confirmReceipt',
        params: data,

    })
}

// 申请开票
export function applyInvoice(data) {
    return request({
        method: 'get',
        url: 'applyInvoice',
        params: data,

    })
}

// 发货
export function trackingNumber(data) {
    return request({
        method: 'get',
        url: 'trackingNumber',
        params: data,

    })
}

// 上传电子发票开票

export function confirmInvoice(data) {
    return request({
        method: 'get',
        url: 'confirmInvoice',
        params: data,

    })
}

// 单商品
export function goodsToOrder(data) {
    return request({
        method: 'get',
        url: 'goodsToOrder',
        params: data,

    })
}
// 评价

export function evaluateOrder(data) {
    return request({
        method: 'post',
        url: 'evaluateOrder',
        data: data,
    })
}

// 编辑商品
export function updateGoods(data) {
    return request({
        method: 'post',
        url: 'updateGoods',
        data: data,
    })
}

// 粮票额度
export function myQuota(data) {
    return request({
        method: 'get',
        url: 'myQuota',
        params: data,

    })
}
// 粮票额度还款
export function repayment(data) {
    return request({
        method: 'post',
        url: 'repayment',
        data: data,
    })
}

// 搜索历史
export function searchList(data) {
    return request({
        method: 'get',
        url: 'searchList',
        data: data,
    })
}

// 删除搜索历史
export function delSearch(data) {
    return request({
        method: 'get',
        url: 'delSearch',
        data: data,
    })
}

// 搜索商品
export function searchGoods(data) {
    return request({
        method: 'get',
        url: 'searchGoods',
        params: data,
    })
}
// 搜索公司
export function searchInfos(data) {
    return request({
        method: 'get',
        url: 'searchInfos',
        params: data,
    })
}
















































































