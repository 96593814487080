import Router from 'vue-router'  //引入vue-router
import login from '@/components/login/index'
import codeLogin from '@/components/login/codeLogin'
import register from '@/components/login/register'
import HelloWorld from '@/components/HelloWorld'
import newPage from '@/views/newPage'
import shopList from '@/views/shopList'
import grainMill from '@/views/grainMill'//粮坊
import grainMarket from '@/views/grainMarket'//粮市
import grainElevator from '@/views/grainElevator'//粮仓
import grainFarmer from '@/views/grainFarmer'//粮农
import shopXq from '@/views/shopXq'//商品详情
import companyXq from '@/views/companyXq'//粮坊详情
import aboutUs from '@/views/aboutUs'//关于我们
import seedList from '@/views/seedList'//粮农的列表
import seedXq from '@/views/seedXq'//粮农的详情
import myFavorite from '@/views/myFavorite'//我的收藏
import joinOus from '@/views/joinOus'//粮道
import newXq from '@/views/newXq'//新闻详情
import orderRelease from '@/views/orderRelease'//发布订单
import orderReleaseGys from '@/views/orderReleaseGys'//供应商发布订单
import cartShop from '@/views/cartShop'//购物车
import addressList from '@/views/addressList'//地址管理列表
import addressInpt from '@/views/addressInpt'//地址管理新增修改

import merchandiseOrderList from '@/views/merchandiseOrder/shopList'//商品买入订单
import merchandiseSaleIndex from '@/views/merchandiseOrder/saleIndex'//商品卖出订单
import merchandiseEvaluateInpt from '@/views/merchandiseOrder/evaluateInpt'//商品评价

import merchandiseEvaluateInptCk from '@/views/merchandiseOrder/evaluateInptCk'//商品评价
import merchandiseEvaluateList from '@/views/merchandiseOrder/evaluateList'//商品评价列表



import merchandiseOrderXq from '@/views/merchandiseOrder/shopXq'//商品买入订单详情
import merchandiseSaleXq from '@/views/merchandiseOrder/saleXq'//商品买入卖出详情

import payOrder from '@/views/merchandiseOrder/payOrder'//商品订单详情待付款购物车进的
import onePayOrder from '@/views/merchandiseOrder/onePayOrder'//商品订单详情待付款订单进的

import sureOrder from '@/views/merchandiseOrder/sureOrder'//商品订单详情确认订单





import grainCoupon from '@/views/grainCoupon'//粮票记录
import manuFacturer from '@/views/enter/manuFacturer'//粮市(成品制造商)入驻
import purchasingAgent from '@/views/enter/purchasingAgent'//粮坊(采购商)入驻
import supplierRz from '@/views/enter/supplierRz'//粮仓(供应商)入驻
import driverRz from '@/views/enter/driverRz'//粮运(司机)入驻
import farmingRz from '@/views/enter/farmingRz'//粮耕(粮农)入驻

import adoutXq from '@/views/adoutXq'//文章详情不带接口
import collectionInformation from '@/views/collectionInformation'//收款信息
import xieyiCont from '@/views/xieyiCont'//协议
import foodCoupon from '@/views/foodCoupon'//粮票提交申请
import purchaserDb from '@/views/order/purchaserDb'//粮坊(采购商)待办订单列表
import purchaserDbXq from '@/views/order/purchaserDbXq'//粮坊(采购商)待办订单详情
import driveXq from '@/views/order/driveXq'//粮坊(采购商)待办订单详情
import purchaserOrderAll from '@/views/order/purchaserOrderAll'//粮坊(采购商)全部订单
import ysSh from '@/views/order/ysSh'//粮坊(采购商)核验收货
import payPz from '@/views/order/payPz'//粮坊(采购商)上传付款凭证
import logisticsDistribution from '@/views/order/logisticsDistribution'//物流配车
import hyzcgbInpt from '@/views/order/hyzcgbInpt'//自检装车过磅
import supplierOrderAll from '@/views/order/supplierOrderAll'//自检装车过磅
import driveList from '@/views/order/driveList'//粮运(司机)列表

import lbXq from '@/views/lbXq'//轮播详情

import driveXqOrder from '@/views/order/driveXqOrder'//粮运(司机)详情
import applyFor from '@/views/applyFor'//粮运(司机)详情

import xqXq from '@/views/xqXq'//需求详情申请

import freightTemplate from '@/views/freightTemplate'//运费模版
import freightTemplateInpt from '@/views/freightTemplateInpt'//运费模版添加
import addShop from '@/views/addShop'//企业商品列表
import addShopInpt from '@/views/addShopInpt'//企业商品添加
import temaiList from '@/views/temaiList'//特卖列表

import invoiceAdmin from '@/views/invoiceAdmin'//发票管理
import invoiceAdminInpt from '@/views/invoiceAdminInpt'//发票管理
import lpBox from '@/views/lpBox'//粮票额度总览
import repaymentInpt from '@/views/repaymentInpt'//粮票额度还款

import grainFarmerSearch from '@/views/grainFarmerSearch.vue'//粮耕搜索
import grainFarmerSearchContent from '@/views/grainFarmerSearchContent.vue'//粮耕搜索内容

import allSearch from '@/views/allSearch.vue'//全局搜索内容


export default new Router({
  routes: [              //配置路由，这里是个数组
    {                    //每一个链接都是一个对象
      path: '/',         //链接路径
      name: 'HelloWorld',     //路由名称，
      component: HelloWorld   //对应的组件模板
      // component: ()=>{
      //   import("../components/HelloWorld").catch(err=>err)
      // }   //对应的组件模板

    },
    {                    //每一个链接都是一个对象
      path: '/codeLogin',         //链接路径
      name: 'codeLogin',     //路由名称，
      component: codeLogin,   //对应的组件模板
      meta: {
        flag: true,
      }
    },
    {                    //每一个链接都是一个对象
      path: '/login',         //链接路径
      name: 'login',     //路由名称，
      component: login,//对应的组件模板
      meta: {
        flag: true,
      }
    },
    {                    //每一个链接都是一个对象
      path: '/register',         //链接路径
      name: 'register',     //路由名称，
      component: register,   //对应的组件模板
      meta: {
        flag: true,
      }
    },

    {                    //每一个链接都是一个对象
      path: '/newPage',         //链接路径
      name: 'newPage',     //路由名称，
      component: newPage   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/shopList',         //链接路径
      name: 'shopList',     //路由名称，
      component: shopList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/grainMill',         //链接路径
      name: 'grainMill',     //路由名称，
      component: grainMill   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/companyXq',         //链接路径
      name: 'companyXq',     //路由名称，
      component: companyXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/aboutUs',         //链接路径
      name: 'aboutUs',     //路由名称，
      component: aboutUs   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/grainMarket',         //链接路径
      name: 'grainMarket',     //路由名称，
      component: grainMarket   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/grainElevator',         //链接路径
      name: 'grainElevator',     //路由名称，
      component: grainElevator   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/shopXq',         //链接路径
      name: 'shopXq',     //路由名称，
      component: shopXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {                    //每一个链接都是一个对象
      path: '/grainFarmer',         //链接路径
      name: 'grainFarmer',     //路由名称，
      component: grainFarmer   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 
    },
    {
      path: '/seedList',         //链接路径
      name: 'seedList',     //路由名称，
      component: seedList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/seedXq',         //链接路径
      name: 'seedXq',     //路由名称，
      component: seedXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/myFavorite',         //链接路径
      name: 'myFavorite',     //路由名称，
      component: myFavorite   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/joinOus',         //链接路径
      name: 'joinOus',     //路由名称，
      component: joinOus   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/newXq',         //链接路径
      name: 'newXq',     //路由名称，
      component: newXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/orderRelease',         //链接路径
      name: 'orderRelease',     //路由名称，
      component: orderRelease   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/orderReleaseGys',         //链接路径
      name: 'orderReleaseGys',     //路由名称，
      component: orderReleaseGys   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/grainCoupon',         //链接路径
      name: 'grainCoupon',     //路由名称，
      component: grainCoupon   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/manuFacturer',         //链接路径
      name: 'manuFacturer',     //路由名称，
      component: manuFacturer   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/purchasingAgent',         //链接路径
      name: 'purchasingAgent',     //路由名称，
      component: purchasingAgent   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/supplierRz',         //链接路径
      name: 'supplierRz',     //路由名称，
      component: supplierRz   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/driverRz',         //链接路径
      name: 'driverRz',     //路由名称，
      component: driverRz   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/farmingRz',         //链接路径
      name: 'farmingRz',     //路由名称，
      component: farmingRz   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/adoutXq',         //链接路径
      name: 'adoutXq',     //路由名称，
      component: adoutXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/collectionInformation',         //链接路径
      name: 'collectionInformation',     //路由名称，
      component: collectionInformation   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/xieyiCont',         //链接路径
      name: 'xieyiCont',     //路由名称，
      component: xieyiCont   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/foodCoupon',         //链接路径
      name: 'foodCoupon',     //路由名称，
      component: foodCoupon   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/purchaserDb',         //链接路径
      name: 'purchaserDb',     //路由名称，
      component: purchaserDb   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/purchaserDbXq',         //链接路径
      name: 'purchaserDbXq',     //路由名称，
      component: purchaserDbXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/driveXq',         //链接路径
      name: 'driveXq',     //路由名称，
      component: driveXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/purchaserOrderAll',         //链接路径
      name: 'purchaserOrderAll',     //路由名称，
      component: purchaserOrderAll   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/ysSh',         //链接路径
      name: 'ysSh',     //路由名称，
      component: ysSh   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/payPz',         //链接路径
      name: 'payPz',     //路由名称，
      component: payPz   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/logisticsDistribution',         //链接路径
      name: 'logisticsDistribution',     //路由名称，
      component: logisticsDistribution   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/hyzcgbInpt',         //链接路径
      name: 'hyzcgbInpt',     //路由名称，
      component: hyzcgbInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/supplierOrderAll',         //链接路径
      name: 'supplierOrderAll',     //路由名称，
      component: supplierOrderAll   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/driveList',         //链接路径
      name: 'driveList',     //路由名称，
      component: driveList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/driveXqOrder',         //链接路径
      name: 'driveXqOrder',     //路由名称，
      component: driveXqOrder   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/applyFor',         //链接路径
      name: 'applyFor',     //路由名称，
      component: applyFor   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/xqXq',         //链接路径
      name: 'xqXq',     //路由名称，
      component: xqXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/lbXq',         //链接路径
      name: 'lbXq',     //路由名称，
      component: lbXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/cartShop',         //链接路径
      name: 'cartShop',     //路由名称，
      component: cartShop   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/addressList',         //链接路径
      name: 'addressList',     //路由名称，
      component: addressList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/addressInpt',         //链接路径
      name: 'addressInpt',     //路由名称，
      component: addressInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/merchandiseOrderList',         //链接路径
      name: 'merchandiseOrderList',     //路由名称，
      component: merchandiseOrderList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/merchandiseSaleIndex',         //链接路径
      name: 'merchandiseSaleIndex',     //路由名称，
      component: merchandiseSaleIndex   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/merchandiseEvaluateInpt',         //链接路径
      name: 'merchandiseEvaluateInpt',     //路由名称，
      component: merchandiseEvaluateInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/merchandiseEvaluateInptCk',         //链接路径
      name: 'merchandiseEvaluateInptCk',     //路由名称，
      component: merchandiseEvaluateInptCk   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    }, {
      path: '/merchandiseEvaluateList',         //链接路径
      name: 'merchandiseEvaluateList',     //路由名称，
      component: merchandiseEvaluateList
      //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/merchandiseOrderXq',         //链接路径
      name: 'merchandiseOrderXq',     //路由名称，
      component: merchandiseOrderXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    }, {
      path: '/merchandiseSaleXq',         //链接路径
      name: 'merchandiseSaleXq',     //路由名称，
      component: merchandiseSaleXq   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/freightTemplate',         //链接路径
      name: 'freightTemplate',     //路由名称，
      component: freightTemplate   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/freightTemplateInpt',         //链接路径
      name: 'freightTemplateInpt',     //路由名称，
      component: freightTemplateInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/addShop',         //链接路径
      name: 'addShop',     //路由名称，
      component: addShop   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/addShopInpt',         //链接路径
      name: 'addShopInpt',     //路由名称，
      component: addShopInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/temaiList',         //链接路径
      name: 'temaiList',     //路由名称，
      component: temaiList   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/payOrder',         //链接路径
      name: 'payOrder',     //路由名称，
      component: payOrder   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/onePayOrder',         //链接路径
      name: 'onePayOrder',     //路由名称，
      component: onePayOrder   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/sureOrder',         //链接路径
      name: 'sureOrder',     //路由名称，
      component: sureOrder   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/invoiceAdmin',         //链接路径
      name: 'invoiceAdmin',     //路由名称，
      component: invoiceAdmin   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },

    {
      path: '/invoiceAdminInpt',         //链接路径
      name: 'invoiceAdminInpt',     //路由名称，
      component: invoiceAdminInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/lpBox',         //链接路径
      name: 'lpBox',     //路由名称，
      component: lpBox   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/repaymentInpt',         //链接路径
      name: 'repaymentInpt',     //路由名称，
      component: repaymentInpt   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/grainFarmerSearch',         //链接路径
      name: 'grainFarmerSearch',     //路由名称，
      component: grainFarmerSearch   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

    },
    {
      path: '/grainFarmerSearchContent',         //链接路径
      name: 'grainFarmerSearchContent',     //路由名称，
      component: grainFarmerSearchContent   //对应的组件模板
      // component: ()=>{
      //   import("../views/newPage").catch(err=>err)
      // } 

        },
        {
            path: '/allSearch',         //链接路径
            name: 'allSearch',     //路由名称，
            component: allSearch   //对应的组件模板
            // component: ()=>{
            //   import("../views/newPage").catch(err=>err)
            // } 
      
          }


    

























  ],
  // mode:history,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})