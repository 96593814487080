<template>
    <div class="content">
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="header">
            <img :src="dataMsg.pic" alt="" />
        </div>
        <div class="cont">
            <div class="contTit">{{ dataMsg.title }}</div>
            <div class="contTip">{{ dataMsg.pubDate }}</div>
            <!-- <div class="contTip">{{dataMsg.createDate}}</div> -->
            <div v-html="dataMsg.content" style="font-size: 0.3rem"></div>
        </div>
    </div>
</template>

<script>
import { getNewsById } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 5,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'static',
            id: '',
            dataMsg: {}
        }
    },
    created() {
        this.id = this.$route.query.id
        this.init()
    },
    methods: {
        init() {
            getNewsById({ newsId: this.id }).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    res.data.content = res.data.content.replace(
                        /<img/g,
                        "<img style='max-width:100%;height:auto;'"
                    )
                    this.dataMsg = res.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    height: 5rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.cont {
    text-align: center;
    width: 14.49rem;
    margin: 0.92rem auto;
    .contTit {
        color: #000000;
        font-size: 0.48rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
    }
    .contTip {
        color: #54545480;
        font-size: 0.16rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }
}
</style>