<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="box">
            <div class="boxTit">粮票当前剩余额度</div>
            <div class="boxNum">{{ dataMsg.usable || 0 }}</div>
            <div class="boxze">
                粮票总额度<span>{{ dataMsg.totalQuota || 0 }}</span>
            </div>
            <div class="boxze">
                待还粮票<span class="dh">{{ dataMsg.pending || 0 }}</span>
            </div>
            <div class="boxBtn" @click="toUrl('/repaymentInpt')">立即还款</div>

            <div class="jl" @click="toUrl('/grainCoupon')">全部账单记录</div>
        </div>
    </div>
</template>

<script>
import { myQuota } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: 'rgba(0,0,0,0.5)',
            color: '#cdcdcd',
            activeColor: '#ffffff',
            position: 'fixed',
            dataMsg: {}
        }
    },
    created() {
        this.init()
    },
    methods: {
        toUrl(urlSrc) {
            this.$router.push(urlSrc)
        },
        init() {
            myQuota().then((res) => {
                this.dataMsg = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.4rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.box {
    width: 15.12rem;
    border-radius: 0.2rem;
    margin: 1.05rem auto 0;
    background: #ffffff;

    padding-top: 1.16rem;
    padding-bottom: 0.9rem;

    .boxTit {
        color: #343231;
        font-weight: 400;
        font-size: 0.32rem;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }
    .boxNum {
        color: #000000;
        font-size: 1rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        margin-top: 0.43rem;
        text-align: center;
    }
    .boxze {
        color: #343231;
        font-size: 0.32rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-top: 0.31rem;
        text-align: center;
        span {
            font-weight: 700;
        }
        .dh {
            color: #0e8f46;
            margin-left: 0.05rem;
        }
    }
    .boxBtn {
        width: 5.24rem;
        height: 0.98rem;
        border-radius: 1rem;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.42rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
        margin: 0.58rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .jl {
        color: #34323166;
        font-size: 0.32rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
        margin-top: 0.4rem;
    }
}
</style>