<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="box" v-for="(item, index) in ghMsg" :key="index">
            <div class="boxTit">
                <img src="../assets/zhuanghu.png" alt="" />
                公户信息
            </div>
            <div class="boxLi">
                <div class="boxLeft">公户名称</div>
                <div class="boxRight">{{ item.accountName || '' }}</div>
            </div>
            <div class="boxLi">
                <div class="boxLeft">开户银行</div>
                <div class="boxRight">{{ item.bank || '' }}</div>
            </div>

            <div class="boxLi">
                <div class="boxLeft">银行卡号</div>
                <div class="boxRight">{{ item.accountNumber || '' }}</div>
            </div>
        </div>

        <div class="box">
            <div class="boxJe">归还粮票数量</div>
            <div class="boxInpt">
                ￥<input
                    type="number"
                    v-model="number"
                    placeholder="自定义输入归还粮票数量"
                />
            </div>
        </div>
        <div class="box">
            <div class="boxTip">
                <span> * </span>
                备注
            </div>
            <div class="boxText">
                <textarea
                    v-model="mark"
                    placeholder="请在这里输入备注~"
                ></textarea>
                <div class="boxTextNum">0/500</div>
            </div>
            <div class="boxTip" style="margin-top: 0.45rem">
                <span> * </span>
                上传凭证
            </div>
            <div class="boxImage">
                <div
                    class="imagePer"
                    v-for="(item, index) in proof"
                    :key="index"
                >
                    <img :src="item" alt="" @click="checkInvoice(item)" />

                    <div class="boxClose" @click.stop="deleteImg(index, 1)">
                        <img src="@/assets/delete.png" alt="" />
                    </div>
                </div>
                <div
                    class="imagePer"
                    @click="triggerFileInput"
                    v-if="proof.length < 3"
                >
                    <input
                        type="file"
                        @change="handleFileChange"
                        ref="fileInput"
                        name="avatar"
                        id="avatar"
                        accept="image/*"
                        style="display: none"
                    />
                    <div class="notu">
                        <img src="../assets/photo.png" alt="" />
                        <div>图片</div>
                        <div class="notuTip">最多3张</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btnFoot" @click="submit">确认提交</div>

        <el-dialog :visible.sync="dialogVisible" width="30%">
            <img width="100%" :src="imageSrc" alt="预览图片" />
        </el-dialog>
    </div>
</template>

<script>
import { upload, lzdAccount, repayment } from '@/api/user/index.js'
export default {
    data() {
        return {
            imageSrc: '',
            dialogVisible: false,
            dataNum: 0,
            background: 'rgba(0,0,0,0.5)',
            color: '#cdcdcd',
            activeColor: '#ffffff',
            position: 'fixed',
            mark: '',
            proof: [],
            ghMsg: [],
            number: '',
            btnFlag: false
        }
    },

    created() {
        this.getGhMsg()
    },
    methods: {
        submit() {
            if (this.number <= 0) {
                this.$message({
                    message: '输入金额有误',
                    type: 'warning'
                })
                return
            }
            if (!this.mark) {
                this.$message({
                    message: '请输入备注',
                    type: 'warning'
                })
                return
            }
            if (this.proof.length <= 0) {
                this.$message({
                    message: '请上传凭证',
                    type: 'warning'
                })
                return
            }
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            repayment({
                number: this.number,
                mark: this.mark,
                proof: this.proof.join(',')
            }).then((res) => {
                this.btnFlag = false
                if (res.code == 0) {
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1500)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
            })
        },
        getGhMsg() {
            lzdAccount().then((res) => {
                this.ghMsg = res.data
            })
        },
        // 查看图片
        checkInvoice(url) {
            this.imageSrc = url
            this.dialogVisible = true
        },
        // 删除图片
        deleteImg(index) {
            this.proof.splice(index, 1)
        },
        // 上传图片
        triggerFileInput() {
            this.$refs['fileInput'].click()
        },
        handleFileChange(event) {
            console.log(event)
            this.selectedFile = event.target.files[0]
            if (!this.selectedFile) {
                return
            }
            const formData = new FormData()
            formData.append('file', this.selectedFile)
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            upload(formData)
                .then((res) => {
                    loading.close()
                    this.proof.push(res.data.src)
                })
                .catch((error) => {
                    console.error('Error uploading image:', error)
                    // 处理上传失败后的逻辑
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 1rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.box {
    width: 15.12rem;
    border-radius: 0.3491rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
    margin: 0 auto;
    padding: 0.45rem 0.33rem;
    box-sizing: border-box;
    margin-bottom: 0.32rem;
    .boxTip {
        color: #253a70;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        span {
            color: #f92800;
        }
    }
    .boxImage {
        display: flex;
        margin-top: 0.3rem;

        .imagePer {
            width: 1.72rem;
            height: 1.72rem;
            border-radius: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f6fb;
            margin-right: 0.3rem;

            position: relative;
            .boxClose {
                width: 0.5rem;
                height: 0.5rem;
                position: absolute;
                top: -0.1rem;
                right: -0.1rem;
                img {
                    width: 100%;
                    height: 100%;

                    display: block;
                }
            }
            .notu {
                img {
                    width: 0.72rem;

                    height: 0.72rem;

                    display: block;
                }
                color: #b8c2d9;
                font-size: 0.24rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                .notuTip {
                    color: #89898980;
                    font-size: 0.18rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                }
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.26rem;
                object-fit: cover;
            }
        }

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }
    }
    .boxText {
        width: 100%;
        border-radius: 0.2rem;
        background: #faf9fc;
        padding: 0.27rem 0.36rem;
        box-sizing: border-box;
        margin-top: 0.2rem;

        textarea {
            border: none;
            width: 100%;
            height: 100%;
            background: #faf9fc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        .boxTextNum {
            color: #b8c2d9;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: right;
        }
    }
    .boxJe {
        color: #1b1b1b;
        font-size: 0.26rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
    }
    .boxInpt {
        color: #1b1b1b;
        font-size: 0.38rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';

        display: flex;
        align-items: center;
        margin-top: 0.25rem;
        input {
            flex: 1;
            margin-left: 0.12rem;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }
    .boxTit {
        color: #1b1b1b;
        font-size: 0.28rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        img {
            width: 0.36rem;
            height: 0.36rem;
            display: block;
        }
    }
    .boxLi {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.3rem;
        .boxLeft {
            color: #868588;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        .boxRight {
            color: #555555;
            font-size: 0.26rem;

            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
}
.btnFoot {
    width: 6.95rem;
    height: 0.88rem;
    border-radius: 0.6rem;
    background: #0e8f46;
    color: #ffffff;
    font-size: 0.36rem;
    font-weight: 500;
    font-family: 'SourceHanSansCN';
    margin: 0.78rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>