<template>
    <div class="content">
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="searchBox">
            <input placeholder="请输入关键字" v-model="keyWord" type="text" />
            <div @click="searchBtn">搜索</div>
        </div>

        <div class="historyBox">
            <div class="nosj" v-if="dataList.length <= 0">
                <img src="../assets/zwsj.png" mode="" />
                <div class="nosjName">暂无历史搜索记录</div>
            </div>
            <div class="historyTop" v-if="dataList.length > 0">
                <div class="historyTit">历史搜索</div>
                <div class="historyTopDelete" v-if="deleteFlag">
                    <div class="historyTopDeleteLi" @click="deleteIndexSet">
                        全部删除
                    </div>
                    <div class="historyTopDeleteLine"></div>
                    <div class="historyTopDeleteLi" @click="deleteFlag = false">
                        取消
                    </div>
                </div>
                <div
                    class="historyTopDelete"
                    v-if="!deleteFlag"
                    @click="deleteFlag = true"
                >
                    <img src="../assets/deleteIcon.png" mode="" />
                </div>
            </div>
            <div class="historyCont">
                <div
                    class="historyLi"
                    @click="searchInpt(item)"
                    v-for="(item, index) in dataList"
                    :key="index"
                    @longpress="onLongPress(item, index)"
                >
                    {{ item.keywords }}
                    <div
                        class="historyLiDelete"
                        v-if="deleteFlag"
                        @click.stop="deleteIndex(item, index)"
                    >
                        <!-- <u-icon name="close" color="#ffffff" size="30"></u-icon> -->
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { delSearch, searchList } from '@/api/user'
export default {
    data() {
        return {
            dataNum: 4,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            dataList: [],
            deleteFlag: false,
            keyWord: ''
        }
    },
    created() {
        this.init()
    },
    methods: {
        // 点击历史搜索
        searchInpt(item) {
            this.$router.push({
                path: '/grainFarmerSearchContent',
                query: { name: item.keywords }
            })
        },
        // 长按删除单个数据
        onLongPress(item, index) {
            let that = this
            this.$confirm('确认删除该历史记录', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
                customClass: 'delMsgBox'
            })
                .then(() => {
                    that.deleteIndex(item, index)
                })
                .catch(() => {})
        },
        // 删除单个数据
        deleteIndex(item, index) {
            delSearch({
                id: item.id
            }).then((res) => {
                if (res.code == 0) {
                    this.dataList.splice(index, 1)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
            })
        },
        deleteIndexSet() {
            let that = this
            this.$confirm('确认删除全部历史搜索', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
                customClass: 'delMsgBox'
            })
                .then(() => {
                    delSearch().then((res) => {
                        if (res.code == 0) {
                            that.dataList = []
                            that.deleteFlag = false
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            })
                        }
                    })
                })
                .catch(() => {})
        },
        // 请求历史搜索
        init() {
            searchList().then((res) => {
                if (res.code == 0) {
                    this.dataList = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
            })
        },
        searchBtn() {
            if (!this.keyWord) {
                this.$message({
                    message: '请输入搜索内容',
                    type: 'warning'
                })
                return
            }
            this.$router.push({
                path: '/grainFarmerSearchContent',
                query: { name: this.keyWord }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.searchBox {
    width: 15.5531rem;
    height: 1rem;
    border-radius: 0.31rem;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    padding: 0 0.49rem;
    margin: 0.9rem 0 0 1.2rem;
    display: flex;
    align-items: center;

    input {
        flex: 1;
        color: #121212;
        font-size: 0.4rem;
        font-weight: 400;
    }

    div {
        font-size: 0.4rem;
        font-weight: 400;
        color: #00943f;
    }
}

.historyBox {
    width: 15.5531rem;
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    margin: 0.2rem 0 0 1.2rem;

    .nosj {
        text-align: center;

        img {
            width: 2rem;
            height: 2rem;
            margin: 1rem auto 0;
        }

        .nosjName {
            color: #aaaaaa;
            text-align: center;
            font-size: 0.5rem;
        }
    }

    .historyTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .historyTit {
            color: #121212;
            font-size: 0.5rem;
            font-weight: 500;
            font-family: 'almmszht';
        }

        .historyTopDelete {
            display: flex;
            align-items: center;

            img {
                width: 0.3rem;
                height: 0.3rem;
            }

            .historyTopDeleteLine {
                width: 1px;
                height: 0.4rem;
                background: #12121280;
                margin: 0 0.1rem;
                box-sizing: border-box;
            }

            .historyTopDeleteLi {
                color: #12121280;
                font-size: 0.3rem;
                font-weight: 400;
                font-family: 'almmszht';
            }
        }
    }

    .historyCont {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .historyLi {
            color: #12121280;
            font-size: 0.4rem;
            font-weight: 400;
            font-family: 'almmszht';
            padding: 0.2rem 0.2rem;
            box-sizing: border-box;
            border-radius: 0.4rem;
            background: #f4f6fb;
            margin-top: 0.2rem;
            height: 1rem;
            margin-right: 0.3rem;

            position: relative;

            .historyLiDelete {
                position: absolute;
                right: 0;
                top: 0;
                height: 56rpx;
                width: 56rpx;
                background: rgba(0, 144, 61, 0.7);
                border-radius: 0 26rpx 26rpx 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>