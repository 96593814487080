var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"bgColor"}),_c('HeaderTop',{attrs:{"parentData":_vm.dataNum,"background":_vm.background,"color":_vm.color,"activeColor":_vm.activeColor,"position":_vm.position}}),_vm._m(0),_c('div',{staticClass:"chooseBig"},[_c('div',{staticClass:"chooseTab"},[_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 99 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(99)}}},[_vm._v(" 全部 ")]),_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 0 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(0)}}},[_vm._v(" 待发货 ")]),_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 1 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(1)}}},[_vm._v(" 待收货 ")]),_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 2 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(2)}}},[_vm._v(" 待结算 ")]),_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 3 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(3)}}},[_vm._v(" 已完成 ")]),_c('div',{staticClass:"chooseTabLi",class:_vm.tabNum == 10 ? 'chooseTabLiA' : '',on:{"click":function($event){return _vm.shoose(10)}}},[_vm._v(" 售后 ")])])]),_c('div',{staticClass:"box"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"boxLi"},[_c('div',{staticClass:"boxTop"},[_c('div',{staticClass:"boxOrder"},[_vm._v(" "+_vm._s(item.orderCode)+" ")]),(
                        (item.status == 8 || item.status == 7) &&
                        item.payStateOne == 0
                    )?_c('div',{staticClass:"boxStatus",staticStyle:{"border":"0.01rem solid #f0153f","background":"#f0153f1a","color":"#f0153f"}},[_vm._v(" 待结算 ")]):_vm._e(),(item.status >= 0 && item.status <= 3)?_c('div',{staticClass:"boxStatus",staticStyle:{"border":"0.01rem solid #ff6633","background":"#fff0eb","color":"#ff6633"}},[_vm._v(" 待发货 ")]):_vm._e(),(item.status >= 4 && item.status <= 6)?_c('div',{staticClass:"boxStatus",staticStyle:{"border":"0.01rem solid #339dff","background":"#339dff1a","color":"#339dff"}},[_vm._v(" 待收货 ")]):_vm._e(),(
                        (item.status == 8 || item.status == 7) &&
                        item.payStateOne == 1
                    )?_c('div',{staticClass:"boxStatus"},[_vm._v(" 已完成 ")]):_vm._e(),(item.status == 10)?_c('div',{staticClass:"boxStatus",staticStyle:{"border":"0.01rem solid #2b62fd","background":"#e8eeff","color":"#2b62fd"}},[_vm._v(" 售后 ")]):_vm._e()]),_c('div',{staticClass:"boxCont"},[_c('div',{staticClass:"boxName"},[_vm._v(" "+_vm._s(item.orderResult.productName)+" ")]),_c('div',{staticClass:"boxContLi"},[(!item.loadNetWeight)?_c('div'):_vm._e(),(item.loadNetWeight)?_c('div',{staticClass:"boxNum"},[_vm._v(" 装车数量："),_c('span',[_vm._v(_vm._s(item.loadNetWeight)+"kg")])]):_vm._e(),(item.orderResult.priceType == 0)?_c('div',{staticClass:"boxPrice"},[_vm._v(" ¥"),_c('span',[_vm._v(_vm._s(item.orderResult.price)+"/斤")])]):_vm._e(),(item.orderResult.priceType == 1)?_c('div',{staticClass:"boxPrice"},[_vm._v(" 以实时价为准 ")]):_vm._e()]),_c('div',{staticClass:"boxContLi",staticStyle:{"margin-top":"0.5rem"}},[_c('div',{staticClass:"boxNum"},[_vm._v(" 粮仓(供货商)："),_c('span',[_vm._v(_vm._s(item.orderResult.companyB))])])]),_c('div',{staticClass:"boxContLi"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"boxNum"},[_vm._v(" 粮运(司机)："),_c('span',[_vm._v(_vm._s(item.driverName))])]),_c('div',{staticClass:"boxNum",staticStyle:{"margin-left":"0.5rem"}},[_vm._v(" 电话："),_c('span',[_vm._v(_vm._s(item.driverMobile))])])]),_c('div',{staticClass:"boxBtn"},[(item.status >= 4 && item.status <= 6)?_c('div',{staticClass:"boxBtnLi",on:{"click":function($event){$event.stopPropagation();return _vm.toUrl(
                                    '/ysSh',
                                    item.id,
                                    item.orderResult.productName
                                )}}},[_c('img',{attrs:{"src":require("@/assets/ddhtbtn.png"),"alt":""}}),_vm._v(" 核验收货 ")]):_vm._e(),(item.status == 8 && item.payStateOne == 0)?_c('div',{staticClass:"boxBtnLi",on:{"click":function($event){$event.stopPropagation();return _vm.toUrl(
                                    '/payPz',
                                    item.id,
                                    item.orderResult.productName
                                )}}},[_c('img',{attrs:{"src":require("@/assets/ddhtbtn.png"),"alt":""}}),_vm._v(" 上传付款凭证 ")]):_vm._e(),_c('div',{staticClass:"boxBtnLi",on:{"click":function($event){$event.stopPropagation();return _vm.toUrl('/driveXq', item.id)}}},[_c('img',{attrs:{"src":require("@/assets/ddhtbtn.png"),"alt":""}}),_vm._v(" 查看详情 ")])])])])])}),0),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end","width":"14.29rem","margin":"0.5rem auto"}},[_c('el-pagination',{attrs:{"current-page":_vm.listQuery.page,"page-sizes":[10],"page-size":_vm.listQuery.limit,"total":_vm.listQuery.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.pageSizeChangeHandle,"current-change":_vm.pageCurrentChangeHandle}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/cgsqbdd.png"),"alt":""}})])
}]

export { render, staticRenderFns }