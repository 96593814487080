<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="header">
            <img src="@/assets/cgsqbdd.png" alt="" />
        </div>
        <!-- <div class="chooseBig" v-if="userInfo.userType==2">
            <div class="chooseTab">
                <div class="chooseTabLi" :class="tabIndex == 1 ? 'chooseTabLiA' : ''"
                    @click="chooseTop(1)">采购需求</div>
                <div class="chooseTabLi" :class="tabIndex == 2 ? 'chooseTabLiA' : ''" @click="chooseTop(2)">供给订单</div>
            </div>
        </div> -->
        <div class="chooseBig">
            <div class="chooseTab">
                <div
                    class="chooseTabLi"
                    :class="tabNum == 99 ? 'chooseTabLiA' : ''"
                    @click="shoose(99)"
                >
                    全部
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 0 ? 'chooseTabLiA' : ''"
                    @click="shoose(0)"
                >
                    待发货
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 1 ? 'chooseTabLiA' : ''"
                    @click="shoose(1)"
                >
                    待收货
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 2 ? 'chooseTabLiA' : ''"
                    @click="shoose(2)"
                >
                    待结算
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 3 ? 'chooseTabLiA' : ''"
                    @click="shoose(3)"
                >
                    已完成
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 10 ? 'chooseTabLiA' : ''"
                    @click="shoose(10)"
                >
                    售后
                </div>
            </div>
        </div>
        <div class="box">
            <div class="boxLi" v-for="(item, index) in dataList" :key="index">
                <div class="boxTop">
                    <div class="boxOrder">
                        {{ item.orderCode }}
                    </div>
                    <div
                        class="boxStatus"
                        v-if="
                            (item.status == 8 || item.status == 7) &&
                            item.payStateOne == 0
                        "
                        style="
                            border: 0.01rem solid #f0153f;
                            background: #f0153f1a;
                            color: #f0153f;
                        "
                    >
                        待结算
                    </div>
                    <div
                        class="boxStatus"
                        v-if="item.status >= 0 && item.status <= 3"
                        style="
                            border: 0.01rem solid #ff6633;
                            background: #fff0eb;
                            color: #ff6633;
                        "
                    >
                        待发货
                    </div>
                    <div
                        class="boxStatus"
                        v-if="item.status >= 4 && item.status <= 6"
                        style="
                            border: 0.01rem solid #339dff;
                            background: #339dff1a;
                            color: #339dff;
                        "
                    >
                        待收货
                    </div>
                    <div
                        class="boxStatus"
                        v-if="
                            (item.status == 8 || item.status == 7) &&
                            item.payStateOne == 1
                        "
                    >
                        已完成
                    </div>
                    <div
                        class="boxStatus"
                        v-if="item.status == 10"
                        style="
                            border: 0.01rem solid #2b62fd;
                            background: #e8eeff;
                            color: #2b62fd;
                        "
                    >
                        售后
                    </div>
                </div>
                <div class="boxCont">
                    <div class="boxName">
                        {{ item.orderResult.productName }}
                    </div>
                    <div class="boxContLi">
                        <div v-if="!item.loadNetWeight"></div>
                        <div class="boxNum" v-if="item.loadNetWeight">
                            装车数量：<span>{{ item.loadNetWeight }}kg</span>
                        </div>
                        <div
                            class="boxPrice"
                            v-if="item.orderResult.priceType == 0"
                        >
                            ¥<span>{{ item.orderResult.price }}/斤</span>
                        </div>
                        <div
                            class="boxPrice"
                            v-if="item.orderResult.priceType == 1"
                        >
                            以实时价为准
                        </div>
                    </div>
                    <div class="boxContLi" style="margin-top: 0.5rem">
                        <div class="boxNum">
                            粮仓(供货商)：<span>{{
                                item.orderResult.companyB
                            }}</span>
                        </div>
                    </div>
                    <div class="boxContLi">
                        <div style="display: flex; align-items: center">
                            <div class="boxNum">
                                粮运(司机)：<span>{{ item.driverName }}</span>
                            </div>
                            <div class="boxNum" style="margin-left: 0.5rem">
                                电话：<span>{{ item.driverMobile }}</span>
                            </div>
                        </div>
                        <div class="boxBtn">
                            <div
                                class="boxBtnLi"
                                v-if="item.status >= 4 && item.status <= 6"
                                @click.stop="
                                    toUrl(
                                        '/ysSh',
                                        item.id,
                                        item.orderResult.productName
                                    )
                                "
                            >
                                <img src="@/assets/ddhtbtn.png" alt="" />
                                核验收货
                            </div>
                            <div
                                class="boxBtnLi"
                                v-if="item.status == 8 && item.payStateOne == 0"
                                @click.stop="
                                    toUrl(
                                        '/payPz',
                                        item.id,
                                        item.orderResult.productName
                                    )
                                "
                            >
                                <img src="@/assets/ddhtbtn.png" alt="" />
                                上传付款凭证
                            </div>
                            <div
                                class="boxBtnLi"
                                @click.stop="toUrl('/driveXq', item.id)"
                            >
                                <img src="@/assets/ddhtbtn.png" alt="" />
                                查看详情
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 14.29rem;
                margin: 0.5rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>

<script>
import { orderList } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            tabNum: 99,
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: [],
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            tabIndex: 2,
            btnFlag: false
        }
    },
    created() {
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        chooseTop(num) {
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            this.tabIndex = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        toUrl(urlSrc, id, name) {
            if (urlSrc == '/ysSh' || urlSrc == '/payPz') {
                this.$router.push({
                    path: urlSrc,
                    query: { id: id, name: name }
                })
            } else {
                this.$router.push({ path: urlSrc, query: { id: id } })
            }
        },

        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            orderList({
                status: this.tabNum,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                type: this.tabIndex
            }).then((res) => {
                this.btnFlag = false

                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    this.dataList = res.data.records
                }
            })
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 5rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.chooseBig {
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
}

.chooseTab {
    width: 14.29rem;

    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 0;
    padding: 0 2rem;
    box-sizing: border-box;

    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        position: relative;
    }

    .chooseTabLiA::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        // right: 10%;
        background: #239f59;
        width: 100%;
        height: 0.09rem;
        border-radius: 0.08rem;
    }
}

.box {
    .boxLi {
        width: 14.29rem;
        height: 3.97rem;
        border-radius: 0.32rem;
        opacity: 1;
        background: #ffffff;
        margin: 0 auto 0.3rem;

        .boxTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 0.23rem;
            box-sizing: border-box;

            .boxOrder {
                width: 4.3rem;
                height: 0.7rem;
                border-radius: 0.32rem 0 0 0;
                opacity: 1;
                background: linear-gradient(
                    90deg,
                    rgba(47, 165, 99, 0.2) 0%,
                    #2fa56300 100%
                );
                color: #343231;
                font-size: 0.32rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                padding-left: 0.3rem;
            }

            .boxStatus {
                width: 0.9rem;
                height: 0.38rem;
                border-radius: 0.19rem;
                opacity: 1;
                border: 0.01rem solid #5dc827;
                background: #5ac7251a;
                color: #5dc827;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .boxCont {
            padding: 0.27rem 0.23rem 0.5rem 0.3rem;
            box-sizing: border-box;

            .boxName {
                color: #343231;
                font-size: 0.3rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }

            .boxContLi {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .boxNum {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';

                    span {
                        color: #343231;
                    }
                }

                .boxPrice {
                    color: #ff0000;
                    font-weight: 500;
                    text-align: left;
                    font-size: 0.24rem;
                    font-family: 'SourceHanSansCN';

                    span {
                        color: #ff0000;
                        font-weight: 700;
                        font-size: 0.36rem;
                        font-family: 'SourceHanSansCN';
                    }
                }

                .boxBtn {
                    display: flex;
                    align-items: center;

                    .boxBtnLi {
                        margin-left: 0.2rem;
                        min-width: 1.84rem;
                        padding: 0 0.2rem;
                        box-sizing: border-box;
                        height: 0.7rem;
                        border-radius: 0.16rem;
                        opacity: 1;
                        border: 0.01rem solid #eff8f7;
                        background: #edfffd;
                        color: #2fa563;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 0.32rem;
                            height: 0.32rem;
                            margin-right: 0.06rem;
                        }
                    }
                }
            }
        }
    }
}
</style>