// // http.js  
// import axios from 'axios';  
// import {Message} from 'element-ui'
  
// // 创建axios实例  
// console.log(process.env.VUE_APP_BASE_API,45645646)
// console.log('/api',123123)

// const service = axios.create({  
//   baseURL: "/api", // api的base_url  
//   timeout: 5000 // 请求超时时间  
// });  
  
// // 请求拦截器  
// service.interceptors.request.use(  
//   config => {  
//     // 在发送请求之前做些什么  
//     // 例如，设置token  
//     const token = localStorage.getItem('token');
//     if (token) {  
//       config.headers['token'] = token;  
//     }  
//     return config;  
//   },  
//   error => {  
//     // 对请求错误做些什么  
//     console.error('请求出错：', error); // for debug  
//     Promise.reject(error);  
//   }  
// );  
  
// // 响应拦截器  
// service.interceptors.response.use(  
//   response => {  
//     // 对响应数据做点什么  
//     const res = response.data;  
//     if (res.code !== 0) {  
//       // 处理错误  
//       Message.error({  
//         message: res.msg || 'Error',  
//         duration: 5 * 1000  
//       });  
//       return Promise.reject(new Error(res.msg || 'Error'));  
//     } else {  
//       return res;  
//     }  
//   },  
//   error => {  
//     // 对响应错误做点什么  
//     console.error('响应出错：', error); // for debug  
//     Message.error({  
//       message: error.message,  
//       duration: 5 * 1000  
//     });  
//     return Promise.reject(error);  
//   }  
// );  
  
// export default service;

import axios from 'axios' 
import { Message } from 'element-ui'//此处是element-ui如果是plus则是'element-plus'
import router from '@/router'
// 创建axios实例
// console.log(process.env.VUE_APP_BASE_API,45645646)
// console.log('/api',123123)

const request = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url  
    // baseURL: "/api", // api的base_url  
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 3 * 1000, // 请求超时时间
 
})
 
// 请求拦截器
request.interceptors.request.use((config) => {
//token名称以自己的为定，我的是‘token’，如果不需要if这里就可以直接删掉
    const token = localStorage.getItem('token');
    console.log(token);
    if (token) {  
      config.headers['token'] = token;  
    }  
    // config.headers['Content-type'] = 'application/json';
    return config;
}, (error) => {
    return Promise.reject(error)
})
 
request.interceptors.response.use((response) => {

    console.log(response,'response')
//返回码以自己的为定，如果没有安装elementui就换成自己的提示
    let { code, msg } = response.data
    if (code != 0&&msg) {
        if (code == 10020) {
        Message({ message: "请登录后操作" || 'error', type: 'warning' })
            
        } else if (code == 10021) {
        Message({ message: msg || 'error', type: 'warning' })
            
            // router.replace('/login')
            localStorage.clear()
            router.push('/')
        } else {
        Message({ message: msg || 'error', type: 'warning' })
            
        }
    }
    return response.data;//此处可以只返回后端传出的数据（第一层data是axios封装的）
}, (error) => {
    return Promise.reject(error)
})
 
export default request;//记得暴露出去
