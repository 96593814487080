<!-- Countdown.vue -->
<template>
    <div class="countdown">
        <span>{{ formattedMinutes }}:{{ formattedSeconds }}</span>
    </div>
</template>
  
  <script>
export default {
    name: 'countDown',
    props: {
        initialTime: {
            type: Number,
            required: true,
            validator: (value) => value > 0 // 确保传入的时间是正数
        }
    },
    data() {
        return {
            remainingTime: this.initialTime, // 剩余时间（秒）
            timer: null // 存储 setInterval 的返回值
        }
    },
    computed: {
        formattedMinutes() {
            return String(Math.floor(this.remainingTime / 60)).padStart(2, '0')
        },
        formattedSeconds() {
            return String(this.remainingTime % 60).padStart(2, '0')
        }
    },
    methods: {
        startCountdown() {
            this.timer = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--
                } else {
                    this.stopCountdown()
                    this.$emit('countdown-ended') // 触发自定义事件
                }
            }, 1000)
        },
        stopCountdown() {
            clearInterval(this.timer)
            this.timer = null
        }
    },
    mounted() {
        this.startCountdown()
    },
    beforeDestroy() {
        this.stopCountdown()
    }
}
</script>
  
  <style scoped>
.countdown {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
}
</style>